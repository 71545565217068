.dark{
  --card-text-color: var(--blue);
  --card-bg-color: var(--beige);
}

.light{
  --card-text-color: var(--white);
  --card-bg-color: var(--black);
}

.container {
  --size-card: 424px;
  display: flex;
  flex-flow: column wrap;
  border-radius: var(--radius-s);
  transition: border 0.3s ease-in-out;
  height: var(--size-card);
}

.image{
  position: relative;
  height: var(--size-card)
}

.icon{
  --size:28px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: var(--spacing-12);
  right: var(--spacing-12);
  border-radius: 9rem;
  background: rgb(191 194 196 / 25%);
  transition: background-color 0.3s ease-in-out;
  height: var(--size);
  width: var(--size);
}
.icon svg{
  --size: 20px;
  height: var(--size);
  width: var(--size);
  color: var(--white);
}

@media (max-width: 1024px){
  .container{
    --size-card: 320px;
  }
}